import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35b85bb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "intro-content" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("strong", {
      class: "intro-content-title",
      innerHTML: _ctx.title
    }, null, 8, _hoisted_2),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "intro-content-description",
          innerHTML: _ctx.description
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}