
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    title: {
      type: String,
      require: true
    },
    description: {
      type: String || null,
      default: null
    }
  }
});
