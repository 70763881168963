
import { defineComponent, onMounted } from "vue";
import { trackerSetAction, trackerSetClick } from "@/utils/vertebraSdk";
import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";
import { ROUTER_PATH as list } from "@/shared/routerPathMobile";
import { useRouter } from "vue-router";
import { data } from "@/shared/pricing";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import SubIntroContentMobile from "@/components/templates/SubIntroContent/SubIntroContentMobile.vue";
import PricingTime from "@/components/atoms/icon/PricingTime/PricingTime.vue";
import PricingService from "@/components/atoms/icon/PricingService/PricingService.vue";
import PricingEtc from "@/components/atoms/icon/PricingEtc/PricingEtc.vue";
import CheckIconBlue from "@/components/atoms/icon/CheckIconBlue/CheckIconBlue.vue";
import SiteShortcutMobile from "@/components/templates/SiteShortcut/SiteShortcutMobile.vue";
import GuideFaqMobile from "@/components/templates/GuideFaq/GuideFaqMobile.vue";
export default defineComponent({
  components: {
    AppLink,
    SubIntroContentMobile,
    PricingTime,
    PricingService,
    PricingEtc,
    CheckIconBlue,
    SiteShortcutMobile,
    GuideFaqMobile
  },
  setup() {
    useHead({
      title: data.sitemapTitle,
      link: [
        { rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}/pricing` }
      ]
    });
    const trackEventConsultingBottomPricing = () => {
      gtagEvents("요금제-문의하기-footer");
      trackerSetClick("ask-pricing-footer");
    };
    const trackEventConsultingPricing = () => {
      gtagEvents("요금제 문의하기");
      trackerSetClick("ask-pricing");
    };
    const router = useRouter();
    const items = list[3].children;
    const { caption, title, description, faq } = data;
    // router.replace("/pricing/livecall");
    onMounted(() => {
      trackerSetAction("요금제 페이지");
    });
    return {
      items,
      caption,
      title,
      description,
      faq,
      trackEventConsultingBottomPricing,
      trackEventConsultingPricing
    };
  }
});
